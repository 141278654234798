import React, { useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./layout.css";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [pathname]);
  return (
    <React.Fragment>
      <Header />
      <div className="children-container">
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
