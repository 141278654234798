import styles from "./jobOverview.module.scss";
import upload from "../../assets/careers/upload.svg";

const ApplicationForm = () => {
  return (
    <div className={styles.applicationContents}>
      <h2>Fill in your personal information</h2>
      <form action="" className={styles.inputForm}>
        <section>
          <label htmlFor="Full Name">Full name</label>
          <input
            className={styles.form}
            type="text"
            name="Full Name"
            id="fullname"
            placeholder="Enter your full name"
          />
        </section>

        <section>
          <label htmlFor="Email Address">Email address</label>
          <input
            className={styles.form}
            type="text"
            name="Email address"
            id="email"
            placeholder="Enter your email address"
          />
        </section>

        <section>
          <label htmlFor="Phone Number">Phone number</label>
          <input
            className={styles.form}
            type="number"
            name="Phone number"
            id="phonenumber"
            placeholder="Enter your phone number"
          />
        </section>

        <h2 style={{ textAlign: "left" }}>Employment information</h2>
        <section>
          <label htmlFor="resume">Resume</label>
          <div className={styles.fileBox}>
            <input type="file" name="Resume" id="resume" />
            <div>
              <img src={upload} alt="upload" />
              <p>Upload a file</p>
            </div>
          </div>
        </section>
        <section>
          <label htmlFor="letter">
            Cover letter<span>(optional)</span>
          </label>
          <textarea
            className={styles.form}
            // type="text"
            name="Cover letter (optional)"
            id="letter"
            style={{ borderRadius: "1rem" }}
          />
        </section>
        <section>
          <label htmlFor="linkedin">Linkedin Profile URL</label>
          <input
            className={styles.form}
            type="text"
            name="Linkedin Profile URL"
            id="linkedin"
          />
        </section>
        <section>
          <label htmlFor="website">Website, Blog or Portfolio</label>
          <input
            className={styles.form}
            type="text"
            name="Website, Blog or Portfolio"
            id="website"
          />
        </section>
      </form>
      <button className={styles.button2}>Submit Application</button>
    </div>
  );
};

export default ApplicationForm;
