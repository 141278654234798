import React, { useState } from "react";
import styles from "./Blog.module.scss";
import money from "../../assets/blog/money.png";
import news from "../../assets/blog/new.png";
import innerNews from "../../assets/news/innernews.svg";

import { Link } from "react-router-dom";

function Blog() {
  const [tab, setTab] = useState(0);

  const blogs = [money, money, news, news];

  const tabItems = [
    "All",
    "Product update",
    "Telemedicine",
    "Marketing",
    "Operation & Finance",
    "Company news",
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.latestBlog}>
        <div className={styles.latestContent}>
          <span>
            <p>Finance</p>
            <div>
              <p>May 19 2022</p>
              <p>9 minutes ago</p>
            </div>
          </span>
          <h1>How to make money in Nigeria</h1>
          <p>We incubate and invest in technology companies.</p>
          <Link to="/blog/1">Read more</Link>
        </div>
        <section>
          <img src={innerNews} alt="latest blog" />
        </section>
      </div>
      <div className={styles.newsKeywords}>
        {tabItems.map((item, i) => (
          <p
            key={`tab-${i}`}
            style={{ color: tab === i ? "#0880BD" : "" }}
            onClick={() => setTab(i)}
          >
            {item}
          </p>
        ))}
      </div>
      <div className={styles.cardContainer}>
        {blogs.map((blog, i) => {
          return (
            <section className={styles.card} key={`blog-${i}`}>
              <div>
                <img src={blog} alt="money news" />
              </div>
              <div>
                <span>
                  <p>FINANCE</p>
                  <p>30 minutes ago</p>
                </span>
                <h5>How to make money in Nigeria</h5>
                <Link to={`/blog/${i + 1}`}>Read post</Link>
              </div>
            </section>
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
