import React from "react";
import styles from "./OurTeamsPage.module.scss";
import ourTeamsImage from "../../assets/team/team.png";
import linkedIn from "../../assets/team/linkedIn_logo.svg";
import hp from "../../assets/team/headPortfolio.png";
import techOp from "../../assets/team/techAndOperation.png";
import CEO from "../../assets/team/ceo.png";
import { leaders } from "./teamData";

interface TeamsPageProps {}

export const OurTeamsPage: React.FC<TeamsPageProps> = () => {
  const leadersImages = [CEO, hp, techOp];
  return (
    <div className={styles.teamsDetailsContainer}>
      <section className={styles.teamsDetails}>
        <h1>Teams</h1>
        <p>
          Our team, which is made up of very intelligent and talented young
          people, benefits from the variety, breadth, and depth of knowledge and
          experience that we all provide.
        </p>
        <div>
          <img src={ourTeamsImage} alt="teams pictures" />
        </div>
        <p>
          We are distinctive and a force to be reckoned with because of the
          diversity of our staff and how they complement one another. Every
          member of our team views their work as an excellent opportunity to
          leverage cutting-edge technology and assistance to realize their
          original and distinctive ideas that will provide the world with novel
          approaches.
        </p>
      </section>
      <section className={styles.leaders}>
        <article>
          <h2>Leadership</h2>
          {/* <p>
            We incubate and invest in technology companies. We provide the
            resources needed to create great products and enable them to scale
            faster than ever before.
          </p> */}
        </article>
        <article className={styles.leadership}>
          {leaders.map((leader, i) => {
            return (
              <div key={i}>
                <section>
                  <img
                    src={leadersImages[leader.image]}
                    alt="md"
                    className={styles.mdImageBg}
                  />
                </section>
                <figcaption>
                  <span>
                    <h3>{leader.name}</h3>
                    <a href={leader.linkedIn}>
                      <img src={linkedIn} alt="linkedIn logo" />
                    </a>
                  </span>
                  <p>{leader.role}</p>
                </figcaption>
              </div>
            );
          })}
        </article>
      </section>
      <section className={styles.ceoDetailss}>
        <div className={styles.ceoDetails}>
          <section>
            <div>
              <h2>Letter from the CEO</h2>
              <p className={styles.letterfromtheCEO}>
                Welcome to NXT, where we provide AI inventive solutions tailored
                to meet our clients' needs. Our services encompass task
                automation, process optimization, and the development of bespoke
                software to address unique requirements. Our team comprises
                talented young individuals well-versed in cutting-edge
                technologies. If you seek top-notch services and business
                solutions in trade and technology, don't hesitate to contact us.
              </p>
            </div>
            <h3>Tosin Obafemi</h3>
          </section>
          <section className={styles.ceoPics}>
            <img src={CEO} alt="ceo" />
          </section>
        </div>
      </section>
    </div>
  );
};

export default OurTeamsPage;
