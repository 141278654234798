import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import InvestmentPage from "./pages/investmentPage/InvestmentPage";
import BlogPage from "./pages/BlogPage";
import NewLandingPage from "./pages/NewLandingPage/NewLandingPage";
import CsrPage from "./pages/CsrPage/CsrPage";
import OurTeamsPage from "./pages/OurTeamsPage/OurTeamsPage";
import CareersPage from "./pages/CareersPage/CareersPage";
import JobOverview from "./components/jobOverview/jobOverview";
import InnerNews from "./components/news/innerNews/innerNews";
import AboutUs from "./pages/AboutUs/AboutUs";
import Layout from "./components/Layout";
import { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import splash from "./assets/Animations/splash.gif";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {};
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          display: !loading ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* <iframe src="https://embed.lottiefiles.com/animation/98432"></iframe> */}
        <img src={splash} alt="splash" height={150} width={150} />
      </div>
      <section style={{ display: loading ? "none" : "" }}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/team" element={<OurTeamsPage />} />
              <Route path="/contact-us" element={<ContactPage />} />
              <Route path="/investment/:name" element={<InvestmentPage />} />
              <Route path="careers" element={<CareersPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/" element={<NewLandingPage />} />
              <Route path="/csr" element={<CsrPage />} />
              <Route
                path="/careers/job-overview/:id"
                element={<JobOverview />}
              />
              <Route path="/blog/:id" element={<InnerNews />} />
              <Route path="/about-us" element={<AboutUs />} />
            </Route>
          </Routes>
        </Router>
      </section>
    </div>
  );
}

export default hot(App);
