import React, { useContext, useEffect, useState } from "react";
import styles from "./innerNews.module.scss";
import innernews from "../../../assets/news/innernews.svg";
import writer from "../../../assets/news/writer.svg";
import money from "../../../assets/blog/money.png";
import anotherBlog from "../../../assets/blog/new.png";
import facebook from "../../../assets/landingPage/facebook.svg";
import twitter from "../../../assets/landingPage/twitter.svg";
import { newsContext } from "../../../context/newsContext";
import { Link, useLocation, useParams } from "react-router-dom";

const InnerNews = () => {
  const { id } = useParams();
  const newsData = useContext(newsContext);
  const [news, setNews] = useState(newsData[0]);
  const blogs = [money, anotherBlog];

  useEffect(() => {
    setNews(() => {
      return newsData.filter((data: any) => data?.id === Number(id))[0];
    });

    return () => {};
  }, [newsData, id]);

  const { pathname } = useLocation();

  const chevron = (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7124 7.79922L4.42907 5.08255C4.7499 4.76172 4.7499 4.23672 4.42907 3.91589L1.7124 1.19922"
        stroke="#CCCCCC"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  return (
    <>
      <div className={styles.innerNewsContainer}>
        <section className={styles.header}>
          <div className={styles.blogHeader}>
            <div className={styles.blogLink}>
              <Link to="/blog">Blog</Link>
              {chevron}
              <p>Blog post</p>
            </div>
            <p>{news?.type}</p>
          </div>
          <h1>{news?.title}</h1>
        </section>

        <div className={styles.newsImage}>
          <img src={innernews} alt="blog" />
        </div>

        <div className={styles.blogNewsSection}>
          <div className={styles.mainText}>
            <div className={styles.leftSide}>
              <div className={styles.blogWriterDetails}>
                <img src={writer} alt="" style={{ borderRadius: "50%" }} />
                <div className={styles.writer}>
                  <h2>{news?.author ?? ""}</h2>
                  <p>{news?.date ?? ""}</p>
                </div>
              </div>
              <section>
                <p>Share article</p>
                <div className={styles.icon}>
                  <a
                    href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20article%20www.nxt.ng${pathname}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="twitter" />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.nxt.ng%2Fblog%2F${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                </div>
              </section>
            </div>
            <div className={styles.blogArticle}>
              <p className={styles.authorNameOnMobile}>By {news?.author}</p>
              <p>
                {news?.article?.map((item: any, index: any) => {
                  return <p key={index}>{item}</p>;
                })}
              </p>
            </div>
          </div>
          <div className={styles.blogConclusion}>
            <h2>Conclusion</h2>
            <p>{news?.conclusion}</p>
          </div>
        </div>
      </div>
      <section className={styles.moreArticle}>
        <h3>More articles</h3>
        <div className={styles.cardContainer}>
          {blogs.map((blog, i) => {
            return (
              <section className={styles.card} key={`blog-${i}`}>
                <div>
                  <img src={blog} alt="money news" />
                </div>
                <div>
                  <span>
                    <p>FINANCE</p>
                    <p>30 minutes ago</p>
                  </span>
                  <h5>How to make money in Nigeria</h5>
                  <Link to={`/blog/${i + 1}`}>Read post</Link>
                </div>
              </section>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default InnerNews;

// https://www.facebook.com/sharer.php?u=https%3A%2F%2Fsupport.microsoft.com%2Fen-us%2Foffice%2Fcreate-a-shareable-link-8257f6da-d5db-4207-a654-925644e3f35c
