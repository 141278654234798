import React from "react";
import styles from "./Contact.module.scss";
import contactUs from "../../assets/contactUs/contactIllustration.svg";
import facebook from "../../assets/contactUs/facebook.svg";
import instagram from "../../assets/contactUs/instagram.svg";
import twitter from "../../assets/contactUs/twitter.svg";
import Button from "@mui/material/Button";
import Modal from "../Modal/Modal";
import ContactForm from "./ContactForm";

export function ContactUs() {
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.texts}>
            <h1>Contact Us</h1>
            <p>
              We incubate and invest in technology companies. We provide the
              resources needed to create great products and enable them to scale
              faster than ever before.
            </p>
          </div>
          <div>
            <img src={contactUs} alt="contact us logo" width="500px" />
          </div>
        </div>
        <div className={styles.contactDetails}>
          <div className={styles.top}>
            <div className={styles.item}>
              <h2>Telephone</h2>
              <span>
                <a href="tel:+2348753697789">+2348753697789</a>
                <a href="tel:+2348753697789">+2348753697789</a>
              </span>
            </div>
            <div className={styles.item}>
              <h2>Address</h2>
              <p>5, Queen Oresola Close, Crystal Estate, Ilupeju</p>
            </div>
          </div>

          <div className={styles.bottom}>
            <div className={styles.item}>
              <h2>Email</h2>
              <span>
                <a
                  href="mailto:partner@nxt.ng"
                  target="_blank"
                  rel="noreferrer"
                >
                  partner@nxt.ng
                </a>
              </span>
            </div>
            <div className={styles.item}>
              <div className={styles.socialMedia}>
                <h2>Social Media</h2>
              </div>
              <div className={styles.icons}>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="instagram" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <img src={facebook} alt="facebook" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <img src={twitter} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inTouchWrap}>
          <Button className={styles.link} onClick={() => setOpen(true)}>
            Get in touch with us
          </Button>
        </div>
      </div>
      <Modal open={open} close={() => setOpen(false)}>
        <ContactForm close={() => setOpen(false)} />
      </Modal>
    </div>
  );
}
