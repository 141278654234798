import React, { createContext, useState } from 'react'
import newsData from '../content/newsData.json'

const newsContext = createContext()
const singleNewsContext = createContext()


const NewsProvider = ({ children }) => {
  const [blog, setBlog] = useState({
    "id": 0,
    "type": "Tech",
    "title": "How to make money online",
    "author": "Janet Jackson",
    "date": "May 10 2021",
    "article": [
      "Effects of the COVID-19 pandemic was the disruption in global supply chains and the crippling of world trade. Though it has been eleven (11) months since the first vaccine received FDA emergency approval and significant progress has been made in terms of economic reopening, the existence of newer variants to the virus as well as uneven vaccination across countries has somewhat dampened the global growth process. Developed economies like the US and UK have achieved 58.40% vaccination rate while Africa as a whole has barely vaccinated 1% of its population.",
      "Effects of the COVID-19 pandemic was the disruption in global supply chains and the crippling of world trade. Though it has been eleven (11) months since the first vaccine received FDA emergency approval and significant progress has been made in terms of economic reopening, the existence of newer variants to the virus as well as uneven vaccination across countries has somewhat dampened the global growth process. Developed economies like the US and UK have achieved 58.40% vaccination rate while Africa as a whole has barely vaccinated 1% of its population.",
      "Effects of the COVID-19 pandemic was the disruption in global supply chains and the crippling of world trade. Though it has been eleven (11) months since the first vaccine received FDA emergency approval and significant progress has been made in terms of economic reopening, the existence of newer variants to the virus as well as uneven vaccination across countries has somewhat dampened the global growth process. Developed economies like the US and UK have achieved 58.40% vaccination rate while Africa as a whole has barely vaccinated 1% of its population."
    ],
    "conclusion": "Major effects of the COVID-19 pandemic was the disruption in global supply chains and the crippling of world trade. Though it has been eleven (11) months since the first vaccine received FDA emergency approval and significant progress has been made in terms of economic reopening, the existence of newer variants to the virus as well as uneven vaccination across countries has somewhat dampened the global growth process. Developed economies like the US and UK have achieved 58.40% vaccination rate while Africa as a whole has barely vaccinated 1% of its population."
  })
  return (
    <newsContext.Provider value={newsData}>
      <singleNewsContext.Provider value={{ blog, setBlog }}>
        {children}
      </singleNewsContext.Provider>
    </newsContext.Provider >
  )
}

export { NewsProvider, newsContext, singleNewsContext }