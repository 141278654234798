import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mainLogo from "../../assets/landingPage/logo.svg";
import styles from "./jobOverview.module.scss";
import data from "../../content/data.json";
import ApplicationForm from "./ApplicationForm";

interface JobOverviewProps {}

const JobOverview: React.FC<JobOverviewProps> = () => {
  const { id } = useParams();

  const [job, setJob] = useState({
    role: "",
    contract: "",
    location: "",
    "job-description": [""],
    "job-requirements": [""],
  });

  useEffect(() => {
    if (id) {
      setJob(() => {
        return data.filter((d) => d.id === Number(id))[0];
      });
    }

    return () => {};
  }, [id]);

  const [tab, setTab] = useState(true);

  return (
    <div className={styles.jobOverviewContainer}>
      <div className={styles.logoBox}>
        <img src={mainLogo} alt="" className={styles.logo} />
      </div>
      <div className={styles.jobNavLink}>
        <a className={styles.link} href="/careers">
          Career
        </a>
        <svg
          width="6"
          height="9"
          viewBox="0 0 6 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.7124 7.79922L4.42907 5.08255C4.7499 4.76172 4.7499 4.23672 4.42907 3.91589L1.7124 1.19922"
            stroke="#CCCCCC"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Job Overview</p>
      </div>
      <div className={styles.jobDescription}>
        <div className={styles.jobDetails}>
          <h1>{job?.role}</h1>
          <p>{job?.contract}</p>
        </div>
        <p>Location: {job?.location}</p>
      </div>
      <div className={styles.applicationHeader}>
        <div
          className={styles.overview}
          onClick={() => setTab(true)}
          style={{ color: tab ? "#0880BD" : "#999999" }}
        >
          Overview
        </div>
        <div
          className={`${styles.overview} `}
          onClick={() => setTab(false)}
          style={{ color: !tab ? "#0880BD" : "#999999" }}
        >
          Application
        </div>
      </div>

      {tab ? (
        <div className={styles.overviewContents}>
          <div>
            <h2>Who we are</h2>
            <p>
              NXT is a developing company with a remarkable group of young
              people, who are driven to use technology to develop the finest
              solutions, provide world-class digital transformation, address
              business issues, and raise consumer happiness.
            </p>
          </div>
          <div>
            <h2>Job Description</h2>
            <ul className={styles.list}>
              {job["job-description"].map((desc: any, index: any) => {
                return (
                  <li className={styles.listItem} key={index}>
                    {desc}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2>Requirements</h2>
            <ul className={styles.list}>
              {job["job-requirements"].map((desc: any, index: any) => {
                return (
                  <li className={styles.listItem} key={index}>
                    {desc}
                  </li>
                );
              })}
            </ul>
          </div>
          <p>
            Kindly send us a mail at <b> hello@nxt.ng </b> with your CV and
            cover letter if you think you’re a good fit for this role.
          </p>
          <button
            className={styles.button1}
            onClick={() => {
              setTab(false);
              window.scrollTo(0, 0);
            }}
          >
            Apply for this position
          </button>
        </div>
      ) : (
        <ApplicationForm />
      )}
    </div>
  );
};

export default JobOverview;
