import React, { useEffect, useState } from "react";
import News from "../../components/news/News";
import styles from "./CsrPage.module.scss";
import endSars from "../../assets/csr/endSars.png";
import tennis from "../../assets/csr/tennis.jpeg";
import car from "../../assets/csr/carr.webp"

interface CsrPageProps {}

export const CsrPage: React.FC<CsrPageProps> = () => {
  const [currentCSR, setCurrentCSR] = useState(0);
  const csrData = [endSars, tennis, car];

  useEffect(() => {
    setTimeout(() => {
      if (currentCSR < csrData.length - 1) {
        setCurrentCSR(currentCSR + 1);
      } else {
        setCurrentCSR(0);
      }
    }, 6000);

    return () => {};
  }, [currentCSR, csrData.length]);

  return (
    <div>
      <div className={styles.wrap}>
        <section>
          <h1>Our responsibilities to the community</h1>
          <section className={styles.container}>
            <div className={styles.carousel}>
              <section className={styles.imgContainer}>
                {csrData?.map((data, i) => (
                  <img
                    key={i}
                    src={data}
                    alt={`${i}`}
                    className={`${
                      currentCSR === i ? styles.slide_in : styles.fade_out
                    }`}
                  />
                ))}

                <div className={styles.dots}>
                  {csrData?.map((_, i) => (
                    <span
                      key={`indicator-${i}`}
                      className={`${
                        currentCSR === i ? styles.active_dot : ""
                      } ${styles.dot}`}
                    ></span>
                  ))}
                </div>
              </section>
            </div>
            <div className={styles.list}>
              <article style={{ opacity: currentCSR === 0 ? 1 : 0.3 }}>
                <h3>END SARS Campaign</h3>
                <p>
                  In October 2020, during the End SARS campaign, we played a
                  crucial role in ensuring the safety of demonstrators by
                  supplying face masks and shields. This initiative was part of
                  our commitment to public health and safety, helping to prevent
                  the spread of COVID-19 among those advocating for social
                  justice.
                </p>
              </article>

              <article style={{ opacity: currentCSR === 1 ? 1 : 0.3 }}>
                <h3>TENNIS TOURNAMENT</h3>
                <p>
                  In October 2020, during the End SARS campaign, we played a
                  crucial role in ensuring the safety of demonstrators by
                  supplying face masks and shields. This initiative was part of
                  our commitment to public health and safety, helping to prevent
                  the spread of COVID-19 among those advocating for social
                  justice.
                </p>
              </article>

              <article style={{ opacity: currentCSR === 2 ? 1 : 0.3 }}>
                <h3>PROMOTING ENTREPRENEURSHIP</h3>
                <p>
                  NXT is dedicated to fostering entrepreneurship. In the past
                  two years, we have donated over 10 cars to young, ambitious
                  entrepreneurs. These vehicles have provided crucial support
                  for their businesses, enabling greater mobility, operational
                  efficiency, and growth opportunities. Our aim is to empower
                  the next generation of business leaders and contribute to
                  economic development. We also actively take on interns and
                  young professionals giving them an opportunity to learn and
                  grow in a live environment.
                </p>
              </article>
            </div>
          </section>
        </section>
      </div>
      <div>{/* <News /> */}</div>
    </div>
  );
};

export default CsrPage;
