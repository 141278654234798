import React from "react";
import styles from "./modal.module.scss";

type Props = {
  children?: React.ReactNode;
  open?: Boolean;
  close?: any;
};
const Modal: React.FC<Props> = ({
  children,
  open = false,
  close = () => {},
}) => {
  return (
    <div className={styles.container} style={{ display: !open ? "none" : "" }}>
      <section onClick={close}></section>
      <section>
        <div>{children}</div>
      </section>
    </div>
  );
};

export default Modal;
