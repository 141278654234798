import React from "react";
import { useParams } from "react-router-dom";
import News from "../../components/news/News";
import styles from "./investment.module.scss";
import { data } from "./investments";
import betacare from "../../assets/investmentPage/betacare.png";
import valuepluss from "../../assets/investmentPage/valuepluss.png";
import valueplus from "../../assets/investmentPage/valueplus.png";
import spinlotto from "../../assets/investmentPage/spinlotto.png";
import green from "../../assets/investmentPage/greenInitiative.png";
import hello1 from "../../assets/investmentPage/helloBudgie1.png";
import hello2 from "../../assets/investmentPage/helloBudgie2.png";
import hello3 from "../../assets/investmentPage/helloBudgie3.png";
import learnbeta from "../../assets/investmentPage/learnbeta.png";
import madina from "../../assets/investmentPage/madina.jpeg";
import twingle from "../../assets/investmentPage/twingle.jpeg";
import stake9ja from "../../assets/investmentPage/stake9ja.jpg";

interface InvestmentPageProps {}

export const InvestmentPage: React.FC<InvestmentPageProps> = () => {
  type xyz = keyof typeof data;
  const { name } = useParams<{ name: xyz }>();

  const images = [
    betacare,
    valuepluss,
    hello1,
    hello2,
    hello3,
    spinlotto,
    green,
    learnbeta,
    madina,
    twingle,
    stake9ja,
  ];

  const chevron = (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.473866 10.5C0.33811 10.5 0.20362 10.438 0.109727 10.3179C-0.0576076 10.1035 -0.0305412 9.78459 0.170345 9.60599L4.78615 5.50012L0.170345 1.39425C-0.0305412 1.21563 -0.0576076 0.896756 0.109727 0.682024C0.277343 0.467585 0.575492 0.438242 0.776942 0.617316L5.82942 5.11151C5.93769 5.20752 6 5.34987 6 5.49975C6 5.64978 5.93783 5.79199 5.82942 5.88799L0.776942 10.3822C0.68813 10.4615 0.580426 10.4999 0.473711 10.4999L0.473866 10.5Z"
        fill="#3EC5F0"
      />
    </svg>
  );

  return (
    <div>
      <section className={styles.container}>
        <div>
          <h1>{name}</h1>
          <span className={styles.line}></span>
        </div>

        <div className={styles.mainContent}>
          {data[name!].image.length <= 1 ? (
            <article className={styles.image_wrap}>
              <img src={images[data[name!].image[0]]} alt={name} />
            </article>
          ) : (
            <article className={styles.image_grid}>
              {data[name!].image.map((imgNum, i) => {
                return (
                  <div className={styles[`image${i}`]}>
                    <img src={images[imgNum]} alt={name} />
                  </div>
                );
              })}
            </article>
          )}

          <article className={styles.text_wrap}>
            {data[name!].desc.map((text, i) => (
              <p key={`desc-${i}`}>{text}</p>
            ))}
            {!!data[name!].website && (
              <span>
                <a href={data[name!].website} target="_blank" rel="noreferrer">
                  Visit website
                </a>
                {chevron}
              </span>
            )}
          </article>
        </div>
      </section>

      <section>{/* <News /> */}</section>
    </div>
  );
};

export default InvestmentPage;
