import { useRef, useState } from "react";
import CountUp from "react-countup";
import styles from "./aboutUs.module.scss";
import { standsData, offerData } from "./aboutData";
import teamMembers from "../../assets/aboutUs/threeTeamMembers.png";
import growth from "../../assets/Animations/Growth.gif";
import rocket from "../../assets/Animations/Rocket.gif";
import teamwork from "../../assets/Animations/Teamwork.gif";
import handshake from "../../assets/Animations/Handshake.gif";
import customer from "../../assets/Animations/Customer.gif";
import News from "../../components/news/News";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const [active, setActive] = useState("stand");
  const [moved, setMoved] = useState(false);

  const icons = [rocket, handshake, customer, growth, teamwork];

  const arrow = (
    <svg
      width="18"
      height="29"
      viewBox="0 0 18 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.60955 12.1222C0.115527 13.388 0.125412 15.4515 1.60674 16.7091L14.3815 27.5615C15.874 28.8291 17.0846 28.3263 17.0858 26.4359L17.1004 2.41496C17.1016 0.525753 15.8799 0.0297668 14.3976 1.28606L1.60955 12.1222Z"
        fill="#373435"
      />
    </svg>
  );

  const offerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const moveRight = () => {
    offerRef.current.scrollLeft += 480;
    setMoved(true);
  };

  const moveLeft = () => {
    offerRef.current.scrollLeft -= 480;
    setMoved(false);
  };

  return (
    <div className={styles.container}>
      <section>
        <h1>Who we are</h1>
        <p>
          NXT is a dynamic, solutions-oriented company comprised of intellectual
          and vibrant professionals. Our mission is to deliver exceptional
          solutions that support enterprise growth across diverse sectors.
          Specializing in Mobile Value-Added Services (mVAS), we drive digital
          transformation, nurture business ideas to fruition, and enhance
          consumer satisfaction.
        </p>
        <div>
          <img src={teamMembers} alt="3 team members" />
        </div>

        <p>
          Our team’s extensive knowledge, experience, and over a decade of
          expertise in creative problem-solving, resilience, motivation, and
          trustworthiness give us a competitive edge.
        </p>

        <p>
          At NXT, we are dedicated to offering high-quality services to our
          varied clientele. Our success is rooted in the value we provide across
          key economic sectors. Having made a significant impact in numerous
          African industries, we are now expanding our reach to ensure our
          solutions have a sustainable, global impact. We customize our
          solutions to meet the unique needs of each client, consistently
          exceeding their expectations.
        </p>

        <p>
          We take immense pride in our work and derive great satisfaction from
          completing projects successfully. With a decade of experience, we are
          well-equipped to deliver and implement the solutions our clients
          require. Our greatest satisfaction comes from seeing the smiles on our
          clients' faces when their needs are met.
        </p>
      </section>
      <section className={styles.specialization}>
        <h1>Specialization in mVAS</h1>
        <p>
          As specialists in Mobile Value-Added Services (mVAS), we offer
          innovative mobile solutions that enhance user engagement and provide
          additional value to our clients' services. Our current solutions
          include:
        </p>
        {/* <div>
          <img src={teamMembers} alt="3 team members" />
        </div> */}

        <ul>
          <li>
            <span className="font-bold">Betacare:</span> A healthcare platform
            designed to improve patient care and streamline healthcare services.
          </li>
          <li>
            <span className="font-bold">Twingle: </span> A social networking
            tool aimed at connecting people and fostering community engagement.
          </li>
          <li>
            <span className="font-bold">Valueplus:</span> A digital advertising
            agency focused on mVAS solutions, helping businesses maximize their
            reach and engagement through effective advertising strategies.
          </li>
          <li>
            <span className="font-bold">Madina:</span> A Q-commerce platform
            offering verified products from various vendors with a focus on
            trust, convenience, and community.
          </li>
          <li>
            <span className="font-bold">Learnbeta:</span> A mobile learning
            platform offering personalized, interactive educational content for
            students from kindergarten to senior secondary school.
          </li>
          <li>
            <span className="font-bold">Stake9ja:</span> An innovative online
            lottery gaming platform that offers users the opportunity to play
            against the system or compete with others for various prizes.
          </li>
        </ul>

        <p>
          In addition to these, we have several other solutions, each designed
          to address specific market needs and further our mission of driving
          digital transformation and business growth. Read more about our
          solutions on the Investments page.
        </p>

        <p>
          By emphasizing these strengths, NXT stands out as a leader in
          delivering customized, high-quality solutions that drive enterprise
          growth and digital transformation globally.
        </p>
      </section>

      <section className={styles.offer_and_stands}>
        <div>
          <button
            className={`${styles.button} ${
              active === "stand" ? styles.active : ""
            }`}
            onClick={() => setActive("stand")}
          >
            What we stand for
          </button>
          <button
            className={`${styles.button} ${
              active === "offer" ? styles.active : ""
            }`}
            onClick={() => setActive("offer")}
          >
            What we offer
          </button>
        </div>

        {active === "stand" && (
          <article className={styles.stands}>
            <section>
              {standsData?.map((data, i) => {
                return (
                  <div className={styles.stands_item} key={`stand-${i}`}>
                    <h3>{data?.title}</h3>
                    <p>{data?.desc}</p>
                    <span>
                      <img src={icons[data?.icon]} alt={data?.title} />
                    </span>
                  </div>
                );
              })}
            </section>
          </article>
        )}

        {active === "offer" && (
          <section>
            <div className={styles.navigators}>
              <span
                onClick={moveLeft}
                className={
                  moved ? styles.active_navigator : styles.inactive_navigator
                }
              >
                {arrow}
              </span>
              <span
                onClick={moveRight}
                className={
                  moved ? styles.inactive_navigator : styles.active_navigator
                }
              >
                {arrow}
              </span>
            </div>
            <article className={styles.offers} ref={offerRef}>
              {offerData?.map((offer, i) => {
                return (
                  <div className={styles.offer_item} key={`offer-${i}`}>
                    <section>
                      <h3>{offer.title}</h3>
                      <p>{offer.desc}</p>
                    </section>
                  </div>
                );
              })}
            </article>
          </section>
        )}
      </section>

      <section className={styles.madeUp}>
        <h1>NXT is made up a dedicated team of</h1>
        <article>
          <div>
            <CountUp
              end={126}
              className={styles.count}
              separator=","
              duration={2}
              enableScrollSpy={true}
              scrollSpyDelay={0}
              delay={0}
            />
            <p>Employees</p>
          </div>

          <div>
            <CountUp
              end={31}
              className={styles.count}
              separator=","
              suffix="+"
              duration={2}
              enableScrollSpy={true}
              scrollSpyDelay={0}
              delay={0}
            />
            <p>Employees in Tech</p>
          </div>

          <div>
            <CountUp
              end={95}
              className={styles.count}
              separator=","
              suffix="+"
              duration={2}
              enableScrollSpy={true}
              scrollSpyDelay={0}
              delay={0}
            />
            <p>Employees in Trade</p>
          </div>
        </article>

        <div className={styles.more}>
          <Link to="/team">Know more about our team</Link>
        </div>
      </section>
      {/* <News /> */}
    </div>
  );
};

export default AboutUs;
