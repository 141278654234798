export const standsData = [
  {
    icon: 0,
    title: "Innovation",
    desc: "Our mantra is to leave every space better than we found it. This unwavering commitment drives our creativity and fuels our innovation.",
  },
  {
    icon: 1,
    title: "Respect",
    desc: " We honor and admire the uniqueness of every individual. At NXT, everyone is recognized as special and valued as a gift.",
  },
  {
    icon: 2,
    title: "Customer-Centric Approach",
    desc: "Our fulfillment comes from exceeding our customers' expectations. We deliver solutions that consistently satisfy their needs and surpass their goals.",
  },
  {
    icon: 3,
    title: "Growth",
    desc: "We are dedicated to continuous growth and self-development, investing in opportunities that enable our team members to become their best selves.",
  },
  {
    icon: 4,
    title: "Teamwork",
    desc: "We believe in the power of teamwork. Our organization is composed of highly intelligent individuals who are ready to challenge the status quo and achieve remarkable outcomes together.",
  },
];

export const offerData = [
  {
    icon: 0,
    title: "Mobile Value-Added Services (mVAS)",
    desc: "As an mVAS solutions-oriented company, we provide cutting-edge mobile solutions that boost user engagement and add significant value to our clients' services.",
  },
  {
    icon: 0,
    title: "Professional Expertise",
    desc: "Our team is extensively trained in product development and marketing, fully equipped to meet any client requirements with proficiency and expertise, additionally we boast expertise in the mVAS industry.",
  },

  {
    icon: 1,
    title: "Industry Knowledge",
    desc: "Our comprehensive and up-to-date industry knowledge empowers us to navigate and leverage the tech sector effectively ",
  },

  {
    icon: 2,
    title: "Customer Base",
    desc: "We have cultivated a sizable and internationally growing customer base in the tech sector, reflecting the impact and quality of our solutions.",
  },

  {
    icon: 3,
    title: "Strategic Partnership",
    desc: "We collaborate with businesses across various sectors and tech fields to streamline and enhance all phases of solution creation. Our partnerships are mutually beneficial, allowing us to innovate and build tailored solutions while sharing the resulting revenue with our clients.",
  },
];
