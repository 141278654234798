import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import mainLogo from "../../assets/landingPage/logo.svg";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Dropdown from "../dropdown/Dropdown";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [click, setClick] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const navigate = useNavigate();

  const companyList = [
    {
      action: () => {
        navigate("/about-us");
        closeMobileMenu();
      },
      name: "About Us",
    },
    {
      action: () => {
        navigate("/team");
        closeMobileMenu();
      },
      name: "Our Team",
    },
  ];

  const investmentsList = [
    {
      name: "Valueplus",
      action: () => {
        navigate(`/investment/valueplus`);
        closeMobileMenu();
      },
    },
    {
      name: "Betacare",
      action: () => {
        navigate(`/investment/betacare`);
        closeMobileMenu();
      },
    },
    // {
    //   name: "Ekoplug",
    //   action: () => {
    //     // navigate(`/investment/helloBudgie Nigeria`);
    //     closeMobileMenu();
    //   },
    // },
    {
      name: "Twingle",
      action: () => {
        navigate(`/investment/twingle`);
        closeMobileMenu();
      },
    },
    // {
    //   name: "Spinlotto",
    //   action: () => {
    //     navigate(`/investment/spinlotto`);
    //     closeMobileMenu();
    //   },
    // },
    // {
    //   name: "HelloBudgie Nigeria",
    //   action: () => {
    //     navigate(`/investment/helloBudgie Nigeria`);
    //     closeMobileMenu();
    //   },
    // },
    // {
    //   name: "Madina Supermarket",
    //   action: () => {
    //     navigate(`/investment/the green initiative`);
    //     closeMobileMenu();
    //   },
    // },
    // {
    //   name: "Nitax payroll management system",
    //   action: () => {
    //     navigate(`/investment/Nitax payroll management system`);
    //     closeMobileMenu();
    //   },
    // },
    {
      name: "Learnbeta",
      action: () => {
        navigate(`/investment/learnbeta`);
        closeMobileMenu();
      },
    },
    {
      name: "Madina",
      action: () => {
        navigate(`/investment/madina`);
        closeMobileMenu();
      },
    },
    {
      name: "Stake9ja",
      action: () => {
        navigate(`/investment/stake9ja`);
        closeMobileMenu();
      },
    },
    // {
    //   name: "The Green Initiative",
    //   action: () => {
    //     navigate(`/investment/the green initiative`);
    //     closeMobileMenu();
    //   },
    // },
  ];

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar">
          <div className="navbarr">
            <div className="navbar-container container">
              <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img
                  src={mainLogo}
                  alt="Nitax logo"
                  className="logo  w-[52px] h-[52px]"
                />
              </Link>
              <div className="menu-icon" onClick={handleClick}>
                {click ? (
                  <FaTimes style={{ color: "black" }} />
                ) : (
                  <FaBars style={{ color: "black" }} />
                )}
              </div>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  {/* <Link
                  to="/company"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Company
                </Link> */}
                  <Dropdown name="Company" list={companyList} />
                </li>
                <li className="nav-item">
                  {/* <Link
                  to="/investment"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Investments
                </Link> */}
                  <Dropdown name="Investments" list={investmentsList} />
                </li>
                <li className="nav-item">
                  <Link
                    to="/csr"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    CSR
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/careers"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Careers
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/blog"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Blog
                  </Link>
                </li> */}
                <li className="nav-btn">
                  <Link to="/contact-us" className="btn-link">
                    <button className="contact">Contact us</button>
                  </Link>
                </li>
                <li className="socialMediaIcon">
                  <InstagramIcon sx={{ fontSize: "25px" }} />
                  <FacebookSharpIcon sx={{ fontSize: "25px" }} />
                  <TwitterIcon sx={{ fontSize: "25px" }} />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
};

export default Header;
