import React from "react";
import Blog from "../components/Blog/Blog";

interface BlogPageProps {}

const BlogPage: React.FC<BlogPageProps> = () => {
  return (
    <div>
      <Blog />
    </div>
  );
};

export default BlogPage;
