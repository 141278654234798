import React from "react";
import styles from "./Contact.module.scss";

const ContactForm = ({ close = () => {} }) => {
  const closeSvg = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={close}
      style={{ cursor: "pointer" }}
    >
      <circle cx="20" cy="20" r="20" fill="#ECECEC" />
      <mask id="path-2-inside-1_819_670" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 13.0197L13.0197 12L20.0177 18.9977L26.9803 12L28 13.0197L21.0023 20.0177L28 26.9803L26.9803 28L20.0177 21.0023L13.0197 28L12 26.9803L18.9977 20.0177L12 13.0197Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13.0197L13.0197 12L20.0177 18.9977L26.9803 12L28 13.0197L21.0023 20.0177L28 26.9803L26.9803 28L20.0177 21.0023L13.0197 28L12 26.9803L18.9977 20.0177L12 13.0197Z"
        fill="black"
      />
      <path
        d="M12 13.0197L10.5858 11.6055L9.1716 13.0197L10.5858 14.4339L12 13.0197ZM13.0197 12L14.4339 10.5858L13.0197 9.1716L11.6055 10.5858L13.0197 12ZM20.0177 18.9977L18.6035 20.4119L20.0213 21.8296L21.4355 20.4083L20.0177 18.9977ZM26.9803 12L28.3945 10.5858L26.9767 9.16801L25.5625 10.5893L26.9803 12ZM28 13.0197L29.4142 14.4339L30.8284 13.0197L29.4142 11.6055L28 13.0197ZM21.0023 20.0177L19.5881 18.6035L18.1703 20.0213L19.5917 21.4355L21.0023 20.0177ZM28 26.9803L29.4142 28.3945L30.832 26.9767L29.4106 25.5625L28 26.9803ZM26.9803 28L25.5625 29.4106L26.9767 30.832L28.3945 29.4142L26.9803 28ZM20.0177 21.0023L21.4355 19.5917L20.0213 18.1703L18.6035 19.5881L20.0177 21.0023ZM13.0197 28L11.6055 29.4142L13.0197 30.8284L14.4339 29.4142L13.0197 28ZM12 26.9803L10.5893 25.5625L9.16801 26.9767L10.5858 28.3945L12 26.9803ZM18.9977 20.0177L20.4083 21.4355L21.8297 20.0213L20.4119 18.6035L18.9977 20.0177ZM13.4142 14.434L14.434 13.4142L11.6055 10.5858L10.5858 11.6055L13.4142 14.434ZM11.6056 13.4142L18.6035 20.4119L21.4319 17.5834L14.4339 10.5858L11.6056 13.4142ZM21.4355 20.4083L28.398 13.4106L25.5625 10.5893L18.5999 17.587L21.4355 20.4083ZM25.566 13.4142L26.5858 14.434L29.4142 11.6055L28.3945 10.5858L25.566 13.4142ZM26.5858 11.6056L19.5881 18.6035L22.4166 21.4319L29.4142 14.4339L26.5858 11.6056ZM19.5917 21.4355L26.5893 28.398L29.4106 25.5625L22.413 18.5999L19.5917 21.4355ZM26.5858 25.566L25.566 26.5858L28.3945 29.4142L29.4142 28.3945L26.5858 25.566ZM28.398 26.5893L21.4355 19.5917L18.5999 22.413L25.5625 29.4106L28.398 26.5893ZM18.6035 19.5881L11.6056 26.5858L14.4339 29.4142L21.4319 22.4166L18.6035 19.5881ZM14.434 26.5858L13.4142 25.566L10.5858 28.3945L11.6055 29.4142L14.434 26.5858ZM13.4106 28.398L20.4083 21.4355L17.587 18.5999L10.5893 25.5625L13.4106 28.398ZM20.4119 18.6035L13.4142 11.6056L10.5858 14.4339L17.5834 21.4319L20.4119 18.6035Z"
        fill="black"
        mask="url(#path-2-inside-1_819_670)"
      />
    </svg>
  );

  return (
    <div className={styles.formContainer}>
      <form>
        <div>
          <h3>We’re one message away!</h3>
          {closeSvg}
        </div>

        <section>
          <label htmlFor="">Full Name</label>
          <input type="text" />
        </section>

        <section>
          <label htmlFor="">Email</label>
          <input type="email" />
        </section>

        <section>
          <label htmlFor="">Phone number</label>
          <input type="tel" />
        </section>

        <section>
          <label htmlFor="">Message</label>
          <textarea rows={5} />
        </section>

        <section>
          <button>Submit</button>
        </section>
      </form>
    </div>
  );
};

export default ContactForm;
