import React from "react";
import { ContactUs } from "../components/ContactUs/Contact";

interface ContactPageProps {}

export const ContactPage: React.FC<ContactPageProps> = () => {
  return (
    <div>
      <ContactUs />
    </div>
  );
};

export default ContactPage;
