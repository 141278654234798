export const leaders = [
  {
    name: "Tosin Obafemi",
    role: "Managing Director",
    linkedIn: "https://www.linkedin.com/in/tosinobafemi/",
    image: 0,
  },
  // {
  //   name: "Joshua Ibizugbe",
  //   role: "Head Portfolio",
  //   linkedIn: "",
  //   image: 1,
  // },
  {
    name: "Runo Obafemi",
    role: "Technology & Operations",
    linkedIn: "https://www.linkedin.com/in/runoobafemi/",
    image: 2,
  },
];
