import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./globalStyles/index.scss";
import { JobProvider } from "./context/JobContext";
import { NewsProvider } from "./context/newsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <JobProvider>
      <NewsProvider>
        <App />
      </NewsProvider>
    </JobProvider>
  </React.StrictMode>
);
