import React from "react";
import styles from "./Footer.module.scss";
import nxtLogo from "../../assets/FooterLogo.svg";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.maxWidthSection}>
        <div className={styles.nxtLogo}>
          <img src={nxtLogo} alt={"nxt-logo"} />
        </div>
        <div className={styles.container}>
          <div className={styles.leftSide}>
            <h4>Subscribe to our newsletter</h4>
            <div className={styles.inputSpan}>
              <input type="text" placeholder="Enter your email address" />
              <button>Subscribe</button>
            </div>

            <div className={styles.icons}>
              <a href="https://www.linkedin.com/company/nitax-technologies-limited-nxt/mycompany/">
                <FaLinkedin
                  className="text-white text-2xl"
                  size={28}
                  color="#ffffff"
                />
              </a>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.header}>
              <h4>INVESTMENTS</h4>
              {/* <p>PISI Integrated Services</p> */}
              {/* <p>Eko Plug</p> */}
              <p onClick={() => navigate(`/investment/betacare`)}>Betacare</p>
              <p onClick={() => navigate(`/investment/learnbeta`)}>LearnBeta</p>
              <p onClick={() => navigate(`/investment/madina`)}>Madina</p>
              {/* <p>(NXT) Trade</p> */}
              {/* <p onClick={() => navigate(`/investment/spinlotto`)}>Spinlotto</p> */}
              <p onClick={() => navigate(`/investment/twingle`)}>Twingle</p>
              {/* <p>Telecommunication VAS (NXT)</p> */}
              {/* <p
                onClick={() =>
                  navigate(`/investment/Nitax payroll management system`)
                }
              >
                Nitax payroll system
              </p>
           

              <p onClick={() => navigate(`/investment/helloBudgie Nigeria`)}>
                HelloBudgie Nigeria
              </p> */}
              <p onClick={() => navigate(`/investment/valueplus`)}>ValuePlus</p>
              <p onClick={() => navigate(`/investment/stake9ja`)}>Stake9ja</p>
            </div>
            <div className={styles.header}>
              <h4>COMPANY</h4>
              <p onClick={() => navigate(`/about-us`)}>About</p>
              <p onClick={() => navigate(`/team`)}>Our team</p>
            </div>
            <div className={styles.header}>
              <h4>SUPPORT</h4>
              <a href="tel:+2348753697789">+2348753697789</a>
              <a href="mailto:partner@nxt.ng">partner@nxt.ng</a>
            </div>
          </div>
          <div className={styles.iconsOnMobile}>
            <InstagramIcon sx={{ fontSize: "25px" }} />
            <FacebookSharpIcon sx={{ fontSize: "25px" }} />
            <TwitterIcon sx={{ fontSize: "25px" }} />
          </div>
        </div>
        <div className={styles.footerLine}>
          <hr />
          <p style={{ marginTop: "3rem" }}>
            © 2024 NXT, Inc. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
