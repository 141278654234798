import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./openPositions.module.scss";
import arrow from "../../assets/careers/chervon.svg";

type openPositionsProp = {
  role: string;
  contract: string;
  id: number;
};
const OpenPositions: React.FC<openPositionsProp> = ({ role, contract, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.openPositions}
      onClick={() => navigate(`/careers/job-overview/${id}`)}
    >
      <div className={styles.positions}>
        <p className={styles.role}>{role}</p>
        <p className={styles.contract}>{contract}</p>
      </div>
      <div className={styles.applyLink}>
        <Link to={`/careers/job-overview/${id}`}>
          Apply <img src={arrow} alt="apply" />
        </Link>
      </div>
    </div>
  );
};

export default OpenPositions;
