import React, { createContext, useState } from 'react'
import data from '../content/data.json'

const jobContext = createContext()
const singleJobContext = createContext()



const JobProvider = ({ children }) => {
  const [job, setJob] = useState({
    "id": 0,
    "role": "Product Designer",
    "contract": "Contract",
    "location": "Lagos",
    "job-description": [
      " Manage multiple projects at one time and work with efficiency",
      "Be comfortable creating designs in modern tools like Figma and Sketch",
      "Manage multiple projects at one time and work with efficiency",
      "Be comfortable creating designs in modern tools like Figma and Sketch"
    ],
    "job-requirements": [
      "3+ years professional design experience",
      "3+ years professional design experience",
      "3+ years professional design experience"
    ]
  })
  return (
    <jobContext.Provider value={data}>
      <singleJobContext.Provider value={{ job, setJob }}>
        {children}
      </singleJobContext.Provider>
    </jobContext.Provider >
  )
}

export { JobProvider, jobContext, singleJobContext }