export const data = {
  betacare: {
    desc: [
      "BetaCare is revolutionizing primary healthcare access through its innovative mobile value-added service (mVAS) solution. We empower users to connect with qualified doctors virtually for consultations, access pharmacy services for medication refills, and even schedule lab tests - all conveniently from their mobile phones. Our mission is to make high-quality healthcare affordable and accessible, particularly for underserved communities like low-income households and those in rural areas. BetaCare prioritizes inclusivity, crafting healthcare solutions that put the well-being of every individual at the forefront. Betacare currently serves over 640,000 users across Nigeria ",
    ],
    image: [0],
    website: "https://betacare.ng",
  },
  valueplus: {
    desc: [
      "ValuePlus is a leading global digital advertising platform specializing in mobile Value-Added Services (mVAS). With a strong international presence, ValuePlus offers cutting-edge solutions tailored to businesses seeking to maximize their digital advertising impact. Our platform excels in advanced targeting, engaging ad formats, and transparent analytics, helping clients worldwide effectively reach and engage their audiences. By focusing on the unique challenges and opportunities within the mVAS sector, ValuePlus empowers brands to enhance user experiences and drive superior campaign performance across diverse markets.",
    ],
    image: [1],
    website: "https://valueplusagency.com",
  },
  "helloBudgie Nigeria": {
    desc: [
      "Hello Budgie is a technologically oriented business that allows its clients and consumers to purchase smart mobile devices with an 8–12 month payback plan so as to enable everyone have the benefit of owning a Mobile phone with ease and reaping its benefits.  Hello Budgie Nigeria is in partnership with Fidelity Bank, Airtel, UPS, Credit Registry of Nigeria and Konga.",
    ],
    image: [2, 3, 4],
    website: "",
  },
  "Nitax payroll management system": {
    desc: [
      "Discover Nitax Payroll, a versatile payroll system ideal for small and medium-sized businesses, and charity organizations, and compliant with the latest government legislation. Our customizable solutions cater to the unique needs of users, ensuring seamless adaptation to specific requirements. Nitax excels in handling statutory calculations, providing users with confidence in legal compliance. Our software delivers a comprehensive range of reports, including payslips and payroll analysis, swiftly and accurately. Elevate your payroll management with Nitax—an efficient, user-friendly solution designed to meet diverse organizational needs. ",
    ],
    image: [2, 3, 4],
    website: "",
  },
  spinlotto: {
    desc: [
      "Spin Lotto introduces a dynamic blend of mobile lottery services, featuring Spin and Win alongside the engaging 5 Lotto. As you immerse yourself in the excitement, unlock opportunities to win coveted prizes like airtime, data, and cash. With a focus on recurrent purchases, we add an element of anticipation to the user experience. Embracing simplicity, the 5 Lotto offers an accessible and thrilling USSD/SMS/WEB/APP game. In proud collaboration with leading telecom giants, including Airtel, MTN, and 9mobile, Spin Lotto brings forth a captivating realm of possibilities for its users. ",
    ],
    image: [5],
    website: "https://spinlotto.com.ng",
  },
  learnbeta: {
    desc: [
      "Looking to empower your child's learning journey? LearnBeta, your go-to mobile Value Added Service (mVAS) solution, offering  a comprehensive online tutoring platform for kindergarten through senior secondary school. Access a treasure trove of resources, including bite-sized video tutorials, downloadable course notes, engaging assignments, and quick spot tests, all tailored to your child's specific learning level. LearnBeta goes beyond textbooks with multisensory online lessons, interactive assessments, and even practice mock exams to prepare for real-world challenges. Our mission, fueled by cognitive psychology, learning theory, and neuroscience, is to create brighter futures, one child at a time. With personalized learning paths, interactive elements, and multi-sensory experiences, LearnBeta ensures a transformative educational journey for every student. ",
    ],
    image: [7],
    website: "",
  },
  "the green initiative": {
    desc: [
      "NXT desires to remain at the forefront of technology as it continues to advance. We are working on setting the bar high for green energy and clean technology. As part of a green project, our supermarkets will be powered to 70% by solar energy.",
    ],
    image: [6],
    website: "",
  },
  twingle: {
    desc: [
      "In the dynamic world of young African professionals, Twingle emerges as a powerful mVAS solution fostering meaningful connections. Accessible through Web, USSD, SMS, and a dedicated app, Twingle prioritizes user safety and privacy with robust moderation measures. This carefully curated platform connects verified individuals who share similar ambitions, laying the foundation for long-lasting connections, friendships and relationships. Twingle cultivates a space that encourages repeat interaction, where professionals can thrive in a safe and secure environment focused on forging valuable connections. By joining Twingle, users become part of a vibrant community where connections flourish and friendships become a cornerstone for success.",
    ],
    image: [9],
    website: "https://twingle.ng/",
  },
  madina: {
    desc: [
      "Welcome to Madina, your go-to discount store. Madina offers subscribed users exclusive access to discounted products, special offers, and exciting prizes while shopping. Leveraging technology through our mobile app, web app, SMS, and USSD services, we provide both physical and remote shopping opportunities. Our primary goal is to make cost-efficient everyday goods accessible to Nigerians, complemented by lottery services offering chances to win cash prizes, airtime, data, and shopping points. Subscribers enjoy additional benefits such as free delivery, exclusive offers, and priority access to new products. Join Madina for a budget-friendly and rewarding shopping experience! ",
    ],
    image: [8],
    website: "https://madina.ng/",
  },
  stake9ja: {
    desc: [
      "Stake9ja is an innovative online lottery gaming platform that offers a unique and immersive experience through two main gameplay sections: the Private section, where users play against the system to win pre-allocated prizes, and the General section, where players compete against each other for a shared prize pool. The platform integrates with mobile network operators (MNOs) for seamless payouts and provides robust CRM tools for administrative control, including user management, live chat support, and communication via SMS and email. Designed with a user-friendly interface and secure, reliable operation, Stake9ja aims to revolutionize the online lottery gaming landscape with its comprehensive features and engaging gameplay options.",
    ],
    image: [10],
    website: "https://stake9ja.com/",
  },
};
