import styles from "./CareersPage.module.scss";
import careerspage1 from "../../assets/careerspage1.svg";
import careerspage2 from "../../assets/careerspage2.svg";
import careerspic3 from "../../assets/careerspic3.svg";
import careerspage4 from "../../assets/careerspage4.svg";
import OpenPositions from "../../components/openPositions/openPositions";
import News from "../../components/news/News";

interface CareersPageProps {}

export const CareersPage: React.FC<CareersPageProps> = () => {
  const jobs: any[] = [
    // { role: "Product Designer", contract: "Fulltime", id: 1 },
    // { role: "Frontend Developer", contract: "Contract", id: 2 },
    // { role: "Graphics Designer", contract: "Fulltime", id: 3 },
    // { role: "Backend Developer", contract: "Fulltime", id: 4 },
    // { role: "Product Manager", contract: "Contract", id: 5 },
    // { role: "Product Designer", contract: "Intern", id: 6 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.careersTextContainer}>
          <div className={styles.careersText}>
            <h1>Careers</h1>
            <p className={styles.topText}>
              Working with NXT gives you a workspace devoid of boundaries. It is
              energetic, flexible, active, creative, and dynamic. The chance for
              progress is presented by/through our organization through
              emphasizing ownership and job duties. We are passionate about
              training and up-skilling staff. Hence, The target is for our
              employee to improve by 50% in three months.
            </p>
            <div className={styles.picturesCard}>
              <div className={styles.img1}>
                <img src={careerspage1} alt="careers page 1" />
              </div>
              <div className={styles.img2}>
                <img src={careerspage2} alt="careers page 2" />
              </div>
              <div className={styles.img3}>
                <img src={careerspic3} alt="careers page 3" />
              </div>
              <div className={styles.img4}>
                <img src={careerspage4} alt="careers page 4" />
              </div>
            </div>
            <p className={styles.topText}>
              At NXT, we seek out the best individuals, so our hiring procedure
              is thorough and deliberate. It guarantees that we hire the best
              candidates and that you are fully aware of who we are, what we do,
              and what we expect of you. Our hiring procedure is meant to make
              sure that you and your desired career path fit into our culture.
            </p>
          </div>
        </div>
        <div className={styles.nxtOpenPositions}>
          <h1>Open positions</h1>
          <div className={styles.nxtRoles}>
            {jobs.length !== 0 ? (
              jobs.map((job, i) => {
                return (
                  <OpenPositions
                    role={job?.role}
                    contract={job?.contract}
                    id={job?.id}
                    key={`job-${i}`}
                  />
                );
              })
            ) : (
              <p className={styles.nopositions}>No current open positions</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.careersPageNews}>{/* <News /> */}</div>
    </>
  );
};

export default CareersPage;
