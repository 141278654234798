import { useEffect, useRef, useState } from "react";
import styles from "./dropdown.module.scss";

interface iList {
  action: React.MouseEventHandler<HTMLDivElement>;
  name: String;
}

interface iProps {
  className?: String;
  list: iList[];
  name: String;
}

const Dropdown: React.FC<iProps> = ({ className = "", list, name }) => {
  const [show, setShow] = useState(false);
  let dropdownRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const handler = (e: any) => {
      if (!dropdownRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className={`${className} ${styles.container}`} ref={dropdownRef}>
      <section onClick={() => setShow(!show)}>
        {name}
        <DropIcon />
      </section>
      {
        <section className={styles.menu}>
          {list?.length > 0 ? (
            list?.map((item, i) => {
              return (
                <div
                  key={`list-item-${i}`}
                  onClick={(e) => {
                    item?.action(e);
                    setShow(false);
                  }}
                >
                  {item.name}
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </section>
      }
    </div>
  );
};

const DropIcon = ({ color = "#373435" }) => {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16821 5.59673C5.62603 6.13644 6.37184 6.13241 6.82605 5.59673L10.7456 0.97714C11.2034 0.437435 11.0214 0 10.3382 0H1.65629C0.973471 0 0.794476 0.44166 1.24887 0.97714L5.16821 5.59673Z"
        fill={color}
      />
    </svg>
  );
};

export default Dropdown;
