import React, { useRef } from "react";

interface IScrollAnimation {
  children: React.ReactNode;
  animateIn?: string;
  animateOut?: string;
}

export const ScrollAnimation: React.FC<IScrollAnimation> = ({
  children,
  animateIn = "",
  animateOut = "",
}) => {
  const mainRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const animate = () => {
    const windowHeight = window.innerHeight;
    const elementTop = mainRef.current?.getBoundingClientRect().top;
    const elementVisible = 100;

    if (elementTop < windowHeight - elementVisible) {
      mainRef.current.classList.add(animateIn);
    } else {
      mainRef.current.classList.remove(animateIn);
      mainRef.current.classList.add(animateOut);
    }
  };

  window.addEventListener("scroll", animate);

  return (
    <div ref={mainRef} style={{ opacity: 0 }}>
      {children}
    </div>
  );
};
